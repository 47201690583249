import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../../components/theme/Layout'
import Footer from '../../../components/theme/Footer'
import Header from '../../../components/theme/Header'
import RenderBlock from '../../../components/blocks/RenderBlock'
import ProductHeader from '../../../components/theme/ProductHeader'
import Offers from "../../../components/common/Offers";

const Product = ({data}) => (
  <Layout
    meta={data.productPage.meta}
    urls={data.productPage.urls}
    locale='zh-hant'
  >
    <Header
      urls={data.productPage.urls}
      locale='zh-hant'
    />

    <ProductHeader page={data.productPage} />

    <RenderBlock blocks={data.productPage.blocks} />

    <Footer image={data.productPage.footer_image} />
    <Offers />

  </Layout>
)

export const query = graphql`
query($guid: String!) {
  productPage(guid: { eq: $guid }, locale: {eq:"zh-hant"}) {
    guid
    modification
    title_html
    pack_sizes_name
    pack_sizes_2_name
    pack_sizes
    product_category {
      title
    }
    other_products {
      title_html
      guid
    }
    similar_products {
      title_html
      guid
    }
    footer_image
    product_icon
    product_code
    pack_sizes_name
    pack_sizes_2_name
    pack_sizes
    other_products_title
    buy_online {
      link
      image_url
    }
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...productLocatorBlockFragment
      ...galleryBlockFragment
      ... on AlsoBoughtBlock {
        id
        title
        products {
          id
          guid
          routeSegment
          title_html
          images_url
        }
      }
    }
    images_url
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default Product
