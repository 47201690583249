/* eslint-disable */

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {injectIntl} from 'react-intl';
import reviews from '../../../../content/reviews.json';

const SnippetWrapper = styled.div`
  min-height: 20px;
  ${tw`mb-10`}

  div {
    cursor: pointer;
  }
`

class Reviews extends Component {
  static loadDynamicScript(intl, product, reviews) {
    if (typeof window !== 'undefined') {
      const existingScript = document.getElementById('bazaarVoice');

      let productCode = product.product_code.split('|');
      let family = productCode[0];
      let variant = productCode[1];

      window.bvDCC = {
        catalogData: {
          locale: (intl.locale === 'fr') ? 'fr_CA' : 'en_CA',
          catalogProducts: []
        }
      };

      if (reviews[variant]) {
        reviews[variant].forEach(item => {
          window.bvDCC.catalogData.catalogProducts.push({
            "productId": item.id,
            "productName": (intl.locale === 'fr') ? item.name_fr : item.name,
            "productDescription": (intl.locale === 'fr') ? item.description_fr : item.description,
            "productImageURL": product.images_url[0],
            "productPageURL": `https://${window.location.hostname}/${(intl.locale === 'fr') ? product.urls.fr : product.urls.en}`,
            "brandName": "Royale",
            "categoryPath": [
              {
                "id": product.product_category.guid,
                "Name": product.product_category.title
              }
            ],
            "upcs": item.upcs,
            "family": family
          })
        });

        window.bvCallback = function (BV) {
          for (var i = 0, len = window.bvDCC.catalogData.catalogProducts.length; i < len; ++i) {
            BV.pixel.trackEvent("CatalogUpdate", {
              type: 'Product',
              locale: window.bvDCC.locale,
              catalogProducts: [window.bvDCC.catalogData.catalogProducts[i]]
            });
          }
        }
      };

      if (!existingScript) {
        const script = document.createElement('script');

        let locale = (intl.locale === 'fr') ? 'fr_CA' : 'en_CA';
        let env = (process.env.GATSBY_ACTIVE_ENV === 'production') ? 'production' : 'staging';

        script.src = `https://apps.bazaarvoice.com/deployments/royale-ca/main_site/${env}/${locale}/bv.js`;
        script.id = 'bazaarVoice';
        script.async = true;
        document.body.appendChild(script);
      }
    }
  };

  constructor(props) {
    super(props);

    this.scrollToReviews = this.scrollToReviews.bind(this);
  }

  componentDidMount() {
    const {
      intl,
      product
    } = this.props;

    if (intl.locale === 'en' || intl.locale === 'fr') {
      Reviews.loadDynamicScript(intl, product, reviews);
    }
  }

  scrollToReviews() {
    if (typeof window !== 'undefined') {
      document.getElementById('review-holder').scrollIntoView();
    }
  }

  render() {
    const {
      product,
      intl
    } = this.props;

    if (intl.locale === 'en' || intl.locale === 'fr') {
      let productCode = product.product_code.split('|');
      let variant = productCode[1];

      return (
        <SnippetWrapper>
          <div
            data-bv-show="rating_summary"
            data-bv-product-id={variant}
            onClick={this.scrollToReviews}
          />
        </SnippetWrapper>
      )
    } else {
      return null
    }
  }
}

Reviews.propTypes = {
  code: PropTypes.string.isRequired
}

export default injectIntl(Reviews)
