import React, { Component } from "react"
import styled from '@emotion/styled'
import {FormattedMessage, injectIntl} from 'react-intl';

import Container from '../../common/Container'
import Button from '../../common/Button'
import Icon from '../../common/Icon'
import WhereToBuy from '../../common/WhereToBuy'
import { Flex, Box } from '../../common/Grid'
import IsMobile from '../../common/IsMobile'
import Reviews from '../../common/Reviews'
import getImageBossUrl from "../../../helpers/getImageBossUrl";

const ProductHeaderStyle = styled.h1`
  ${tw`font-light font-montserrat text-black text-4xl leading-tight m-0 mb-8`}
  max-width: 500px;

  p { margin: 0; }
`

const ProductTitles = styled.h3`
  ${tw`font-semibold font-montserrat text-blog-text text-base m-0 mb-6 mt-12`}

  p {
    margin: 0px;
  }
`

const ProductButton = styled(Button)`
  ${tw`font-medium border-border-blue text-black flex justify-center h-full items-center rounded-none block`}
`

const BoxSize = styled.div`
  ${tw`font-raleway font-medium text-base text-black`}
  text-align: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: -8px;
    width: 1px;
    background: #B5B5B5;
    top: 0px;
    bottom: 0px;
  }
`

const BoxSizeWrapper = styled(Box)`
  ${tw`mb-5`}

  &:nth-child(3n+3) > div:after {
    display: none;
  }

  &:last-child > div:after {
    display: none;
  }

  @media screen and (max-width: 64em) {
    &:nth-child(3n+3) > div:after {
      display: block;
    }

    &:nth-child(2n+2) > div:after {
      display: none;
    }
  }
`

const DesktopImageBox = styled(Box)`
  ${tw`pr-20`}

  @media screen and (max-width: 64em) {
    display: none;
  }
`

const ImageBox = styled(Box)`
  ${tw`pr-20`}

  @media screen and (max-width: 64em) {
    ${tw`p-0`}
  }
`

const ProductContainer = styled(Container)`
  ${tw`mt-10`}
`

const Thumbnail = styled.button`
  ${tw`bg-transparent border-none p-0 outline-none hover:cursor-pointer focus:shadow-outline flex items-center justify-center`}
  width: 80px;
  height: 80px;

  & > img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
`

const ThumbnailFlex = styled(Flex)`
  ${tw`mt-5 text-center`}
`

const ImageBoxHolder = styled.div`
  height: 400px;
  position: relative;
  ${tw`flex items-center justify-center`}

  & > div {
    height: 100%;
  }

  img {
    max-height: 100%;
    height: auto;
  }

  @media screen and (max-width: 64em) {
    height: 250px;
  }
`

class ProductHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 0
    };
  }

  changeSlide(index) {
    this.setState({
      slideIndex: index
    })
  }

  handleKeyPress(e, index) {
    e.preventDefault()
    if (e.keyCode === 28) {
      this.setState({
        slideIndex: index
      })
    }
  }

  render() {
    const { page } = this.props
    const { slideIndex } = this.state
    const desktopImage = getImageBossUrl(
      page.images_url[slideIndex],
      {
        operation: 'height',
        height: 400
      }
    );

    const mobileImage = getImageBossUrl(
      page.images_url[slideIndex],
      {
        operation: 'height',
        height: 300
      }
    );

    return (
      <ProductContainer>
        <Flex>
          <DesktopImageBox width={1 / 3}>
            <ImageBoxHolder>
              {page.images_url.length ? <img src={desktopImage} key={slideIndex} loading="lazy" alt="" /> : null }
            </ImageBoxHolder>

            {page.images_url.length > 1
              ?
              (
                <ThumbnailFlex>
                  {page.images_url.map((slide, index) => (
                    <Box width={1 / page.images_url.length}>
                      <Thumbnail
                        type="button"
                        onClick={() => this.changeSlide(index)}
                        onKeyPress={(e) => this.handleKeyPress(e, index)}
                      >
                        <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-imageboss-src={page.images_url[index]} data-imageboss-width="80" alt="" />
                      </Thumbnail>
                    </Box>
                  ))}
                </ThumbnailFlex>
              )
              : null}
          </DesktopImageBox>
          <Box width={[1, 1, 1, 2 / 3]}>
            <ProductHeaderStyle itemProp="name" dangerouslySetInnerHTML={{ __html: page.title_html }} />

            <Reviews product={page} />

            <IsMobile>
              <ImageBox width={1} mb={5}>
                <ImageBoxHolder>
                  {page.images_url.length ? <img src={mobileImage} alt="" /> : null}
                </ImageBoxHolder>

                {page.images_url.length > 1
                  ?
                  (
                    <ThumbnailFlex>
                      {page.images_url.map((slide, index) => (
                        <Box width={1 / page.images_url.length}>
                          <Thumbnail
                            type="button"
                            onClick={() => this.changeSlide(index)}
                            onKeyPress={(e) => this.handleKeyPress(e, index)}
                          >
                            <img data-imageboss-src={page.images_url[index]} src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" width="80" height="80" alt="" />
                          </Thumbnail>
                        </Box>
                      ))}
                    </ThumbnailFlex>
                  )
                  : null}
              </ImageBox>
            </IsMobile>

            {page.buy_online.length ? (
              <WhereToBuy
                buy_online={page.buy_online}
                guid={page.guid}
              />
            ) : null}

            <ProductTitles>{page.pack_sizes_name}</ProductTitles>

            <Flex>
              {page.product_icon !== 'no_icon' ?
                (
                  <Box mr={1}>
                    <Icon icon={page.product_icon} width={88} />
                  </Box>
                )
                : null}
              <Box flex="1">
                <Flex flexWrap='wrap' mx={-2}>
                  {page.pack_sizes.map(size => (
                    <BoxSizeWrapper width={[1/2,1/2,1/2,1 / 3]} px={2}>
                      <BoxSize>{size}</BoxSize>
                    </BoxSizeWrapper>
                  ))}
                </Flex>
              </Box>
            </Flex>

            {page.other_products_title && page.similar_products.length ?
              (
                <React.Fragment>
                  <ProductTitles dangerouslySetInnerHTML={{ __html: page.other_products_title }} />

                  <Flex flexWrap='wrap' mx={[0,0,0,-2]}>
                    {page.similar_products.map(node => (
                      <Box width={[1,1,1,1 / 2]} px={[0,0,0,2]} mb={3}>
                        <ProductButton
                          outline
                          html={node.title_html}
                          to={node.guid}
                        />
                      </Box>
                    ))}
                  </Flex>
                </React.Fragment>
              )
              : null}

            {page.other_products.length ?
              (
                <React.Fragment>
                  <ProductTitles>
                    <FormattedMessage
                      id='other_products'
                      values={{
                        title: page.product_category.title
                      }}
                    />
                  </ProductTitles>

                  <Flex flexWrap='wrap' mx={[0, 0, 0, -2]}>
                    {page.other_products.map(node => (
                      <Box width={[1, 1, 1, 1 / 2]} px={[0, 0, 0, 2]} mb={[3, 3, 3, 3]}>
                        <ProductButton
                          outline
                          html={node.title_html}
                          to={node.guid}
                        />
                      </Box>
                    ))}
                  </Flex>
                </React.Fragment>
              )
              : null
            }
          </Box>
        </Flex>
      </ProductContainer>
    )
  }
}

export default injectIntl(ProductHeader)
