import { ImageBoss } from 'imageboss-js';
import getImagePath from "./getImagePath";

const getImageBossUrl = (image, options) => {
  let imageUrl = image;
  if (imageUrl.includes('http')) {
    imageUrl = getImagePath(imageUrl);
  }

  let defaultOptions = {
    options: {
      quality: 90
    },
    source: 'royale'
  };

  if (typeof window !== 'undefined' && window.localStorage && window.localStorage.getItem('webpsupport') === "true") {
    defaultOptions = {
      ...defaultOptions,
      options: {
        format: 'webp'
      }
    }
  }

  const mergedOptions = {
    ...defaultOptions,
    ...options
  };

  const arrOptions = [];
  Object.keys(mergedOptions.options).forEach(key => {
    arrOptions.push(`${key}:${mergedOptions.options[key]}`);
  });

  mergedOptions.options = arrOptions.join(',');

  return ImageBoss.getURL(
    imageUrl,
    mergedOptions
  );
}

export default getImageBossUrl
